import { graphql } from "gatsby"
import PaintingsGallery from "../components/paintings-gallery"

export default PaintingsGallery

export const query = graphql`
  {
    allFile(
      filter: { sourceInstanceName: { eq: "sf_paintings" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            thumbnail: fluid(maxWidth: 800, maxHeight: 800) {
              ...GatsbyImageSharpFluid
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
